<template>
  <div>
<CCard>
 <CRow >
  <!-- <CCol lg="3" sm="6" md="4" class=" p-4 pl-5 pr-5 ml-5 mr-5 mt-2" v-if="config.getPermission('booking_reports').view && config.getPermission('booking_reports').download"> -->
   <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('booking_reports').view">
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
            Bookings Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="bookingCount > 0">{{bookingCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/report_booking"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/reports.png" width="100" height="100"/><br>
                    <h6> LIST OF BOOKINGS </h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
    <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('booking_charges_reports').view">
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
            Booking Charges Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="bookingChargeCount > 0">{{bookingChargeCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/booking_charges_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/clientBillings.png" width="100" height="100"/><br>
                    <h6> LIST OF BOOKING CHARGES</h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
   <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('equipment_reports').view && !is_trucking()">
   
        <CCard border-color="primary" style=" text-align:center; display:block;" >
          <CCardHeader>
            Equipments Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="equipmentCount > 0">{{equipmentCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/equipment_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/booking.png" width="130" height="100"/><br>
                    <h6> LIST OF EQUIPMENTS </h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
    <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('vehicle_reports').view">
   
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
           Vehicles Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="vehicleCount > 0">{{vehicleCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/vehicle_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/vehicle_Reports.png" width="150" height="100"/><br>
                    <h6> LIST OF VEHICLES </h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
    <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('furnishing_reports').view && !is_trucking()">
   
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
           Furnishing Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="furnishingCount > 0">{{furnishingCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/furnishing_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/invoice.png" width="100" height="100"/><br>
                    <h6> LIST OF  FURNISHING </h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
   <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('workorder_reports').view">
   
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
           Work Orders Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="workordercount > 0">{{workordercount}}</CBadge>
          </CCardHeader>
           <CLink v-if="!is_trucking()" 
          to="/main/workorder_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/work_order.png" width="100" height="100"/><br>
                    <h6> LIST OF  WORK ORDERS </h6>
            </CCardBody>
         </CLink>          
          <CLink v-else
           to="/main/work_order"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/work_order.png" width="100" height="100"/><br>
                    <h6> LIST OF  WORK ORDERS </h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
   <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('dr_reports').view && !is_trucking()">
   
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
           Delivery Receipt Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="drCount > 0">{{drCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/dr_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/dr.png" width="100" height="100"/><br>
                    <h6> LIST OF  DELIVERY RECEIPTS </h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
   <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('mts_reports').view && !is_trucking()">
   
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
           Material Transfer Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="mtsCount > 0">{{mtsCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/mts_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/mts.jpg" width="100" height="100"/><br>
                    <h6> LIST OF  MATERIAL TRANSFERS </h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
   <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2"  v-if="config.getPermission('personnel_settlement_reports').view">
   
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
           Personnel Settlement Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="PSRCount > 0">{{PSRCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/personnel_settlement_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/personnelLedger.png" width="100" height="100"/><br>
                    <h6> LIST OF PERSONNEL SETTLEMENT </h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>
     <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('opex_reports').view">
   
        <CCard border-color="primary" style=" text-align:center; display:block;">
          <CCardHeader>
           Opex Report
            <CBadge shape="pill" color="danger" class="float-right" v-if="opexBadgeCount > 0">{{opexBadgeCount}}</CBadge>
          </CCardHeader>
           <CLink 
          to="/main/opex_reports"
          target="_blank" >
     
            <CCardBody>
            <img src="@/assets/IMAGES/bookings.png" width="100" height="100"/><br>
                    <h6> LIST OF OPERATIONAL EXPENSES</h6>
            </CCardBody>
         </CLink>          
       
      </CCard>
    
   </CCol>

   <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('fuel_record').download">
   
   <CCard border-color="primary" style=" text-align:center; display:block;">
     <CCardHeader>
     Fuel Report
       <CBadge shape="pill" color="danger" class="float-right" v-if="frCount > 0">{{frCount}}</CBadge>
     </CCardHeader>
      <CLink 
     to="/data/fuel_record"
     target="_blank" >

       <CCardBody>
       <img src="@/assets/IMAGES/gas-pump.png" width="100" height="100"/><br>
               <h6> LIST OF FUEL RECORDS</h6>
       </CCardBody>
    </CLink>          
  
 </CCard>

  </CCol>
  <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('incident').download">
   
   <CCard border-color="primary" style=" text-align:center; display:block;">
     <CCardHeader>
     Incident Report
       <CBadge shape="pill" color="danger" class="float-right" v-if="incidentCount > 0">{{incidentCount}}</CBadge>
     </CCardHeader>
      <CLink 
     to="/main/incident"
     target="_blank" >

       <CCardBody>
       <img src="@/assets/IMAGES/incident_report.png" width="100" height="100"/><br>
               <h6> LIST OF INCIDENTS</h6>
       </CCardBody>
    </CLink>          
  
 </CCard>

</CCol>
<!-- <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('incident').download"> -->
  <CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="is_marvel() && config.getPermission('incident').view">
  <CCard border-color="primary" style=" text-align:center; display:block;">
    <CCardHeader>
      Client Billing Summary Report
      <CBadge shape="pill" color="danger" class="float-right" v-if="clientBillingSummary > 0">{{clientBillingSummary}}</CBadge>
    </CCardHeader>
     <CLink 
    to="/main/client_billing_summary_report"
    target="_blank" >

      <CCardBody>
      <img src="@/assets/IMAGES/dr.png" width="100" height="100"/><br>
              <h6> LIST OF CLIENT BILLING SUMMARY</h6>
      </CCardBody>
   </CLink>          
 
</CCard>

</CCol>
<CCol lg="3" sm="6" md="4" class=" p-4 pl-3 pr-3 ml-3 mr-3 mt-2" v-if="config.getPermission('license_renewal').download">
   
   <CCard border-color="primary" style=" text-align:center; display:block;">
     <CCardHeader>
     Personnel Renewal Report
       <CBadge shape="pill" color="danger" class="float-right" v-if="LRCount > 0">{{LRCount}}</CBadge>
     </CCardHeader>
      <CLink 
     to="/data/license_renewal_reminder"
     target="_blank" >

       <CCardBody>
       <img src="@/assets/IMAGES/driver-license.png" width="100" height="100"/><br>
               <h6> LIST OF PERSONNEL RENEWAL</h6>
       </CCardBody>
    </CLink>          
  
 </CCard>

</CCol>
  </CRow>
</CCard>
   

  
    
  </div>
</template>

<script>
import config from '../config.js';
import axios from '../axios';
import moment from 'moment';
export default {
  name: 'Cards',
  data: function () {
    return {
      config,
      furnishingCount: 0,
      equipmentCount: 0,
      bookingCount: 0,
      bookingChargeCount: 0,
      vehicleCount: 0,
      workordercount: 0,
      drCount: 0,
      mtsCount: 0,
      frCount: 0,
      incidentCount: 0,
      clientBillingSummary:0,
      LRCount:0,
      dataParams:{
        total:""
      },
      opexCount : 0,
      PSRCount : 0,
      show: true,
      isCollapsed: true,
      opexBadgeCount : 0,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.'
    }
  },
  mounted(){

    let delivery_date_to = new Date();
	  let delivery_date_from = moment(new Date()).subtract(1, 'month')._d;

    delivery_date_from = moment(delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
  
    delivery_date_to = moment(delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
    delivery_date_to = delivery_date_to + 86399; 

    let transfer_date_from = moment(new Date()).subtract(6, 'month')._d;
	  let transfer_date_to = new Date();
    
    axios.get(config.api_path+'/reports/equipment-badge',{
      params : {
        // delivery_date_to: moment(new Date())._d,
        // delivery_date_from: moment(new Date()).subtract(2, 'weeks')._d, 
      }
    }).then(res => {
        if(res.data.total) this.equipmentCount = res.data.total;

        console.log(res.data.data)
    })
    // axios.get(config.api_path+'/equipment?page=1&limit=10000000')
    // .then(response=>{
    //   this.equipmentCount = response.data.total
    // })

    axios.get(config.api_path+'/booking?page=1&limit=10000000&count=true'
      +'&delivery_date_to='+delivery_date_to
			+'&delivery_date_from='+delivery_date_from)
    .then(response=>{
      this.bookingCount = response.data.total
    })

    let bc_delivery_date_to = new Date();
    let bc_delivery_date_from = moment(new Date()).subtract(7, 'days')._d;
    bc_delivery_date_to = moment(bc_delivery_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
    bc_delivery_date_from = moment(bc_delivery_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 

    axios.get(config.api_path+'/booking_charges',{
      params : {
        page:1,
        limit:10000000,
        count:true,
        delivery_date_to : bc_delivery_date_to,
        delivery_date_from : bc_delivery_date_from
      }
    })
    .then(response=>{
      this.bookingChargeCount = response.data.total
    })

    // axios.get(config.api_path+'/vehicle?page=1&limit=10000000')
    // .then(response=>{
    //   this.vehicleCount = response.data.total
    // })
      
    axios.get(config.api_path+'/reports/vehicle-badge',{
      params : {
      }
    }).then(res => {
        if(res.data.total) this.vehicleCount = res.data.total;
    })

    axios.get(config.api_path+'/reports/furnishing-badge',{
      params : {
      }
    }).then(res => {
        if(res.data.total) this.furnishingCount = res.data.total;
    })

    axios.get(config.api_path+'/reports/workorder-badge',{
      params : {
      }
    }).then(res => {
        if(res.data.total) this.workordercount = res.data.total;
    })
    
   

    transfer_date_from = moment(transfer_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
    transfer_date_to = moment(transfer_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000; 
  
     axios.get(config.api_path+'/deliveryReceipt',{
      params: {
        page:1,
        limit:50000,
        delivery_date_from: transfer_date_from,
        delivery_date_to: transfer_date_to
      }
     })
    .then(response=>{
      this.drCount = response.data.total
    })

     axios.get(config.api_path+'/materialTransfer', {
      params: {
        page:1,
        limit:50000,
        transfer_date_from,
        transfer_date_to
      }
     })
    .then(response=>{
      this.mtsCount = response.data.total
    })

    axios.get(config.api_path+'/fuel_record', {
      params: {
        page:1,
        limit:500000,
       
      }
     })
    .then(response=>{
      this.frCount = response.data.total
    })
    axios.get(config.api_path+'/incident', {
      params: {
        page:1,
        limit:500000,
       
      }
     })
    .then(response=>{
      this.incidentCount = response.data.total
    })


    axios.get(config.api_path+'/license-renewal-reminder', {
      params: {
        page:1,
        limit:500000,
       
      }
     })
    .then(response=>{
      this.LRCount = response.data.total
    })

   

    axios.get(config.api_path+'/personnel/settlement/badge-count',{
      params : {
        delivery_date_to: moment(new Date())._d,
        delivery_date_from: moment(new Date()).subtract(2, 'weeks')._d, 
      }
    }).then(res => {
        if(res.data.data) this.PSRCount = res.data.data;

        console.log(res.data.data)
    })

    axios.get(config.api_path+'/booking-expenses/opex-badge-count',{
      params : {
        delivery_date_to : moment(new Date(), "MM-DD-YYYY").startOf('day').valueOf() / 1000,
			  delivery_date_from : moment(new Date(), "MM-DD-YYYY").subtract(1, 'weeks').valueOf() / 1000,
        company_id : config.global_user_data.company_id
      }
    }).then(res => {
      
      this.opexBadgeCount = res.data.total;
    })

    //   axios.get(config.api_path+'/booking?status=completed&page=1&limit=10000000&count=true')
    // .then(response=>{
    //   this.PSRCount = response.data.total
    // })
    var company_id = config.global_user_data.company_id;
    axios.get(config.api_path + '/client-billing-summary?customer_search='+
            '&date_from=Invalid date' +
            '&date_to=Invalid date' +
            '&company_id=' + company_id +
            '&page=1&limit=10')
    .then(response => {
        this.clientBillingSummary = response.data.data.length;
    })
  },
}
</script>
